import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'wridemo',

  ecomm: {
    baseUrl: 'https://ecomm.wridemo.us.regional.uat.cf.10piececombo.com',
    menuUrl: 'https://ecomm.wridemo.us.regional.uat.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.wridemo.us.regional.uat.cf.10piececombo.com'
  },
  featureFlags: {
    ...base.featureFlags
  },
  legacy: {
    webAppBaseUrl: 'https://uat.wingstop.com',
    webAppStoreMenuBaseUrl: 'https://uat.wingstop.com'
  },
  ngfeBaseUrl: 'https://wridemo.uat.ordering.wingstop.com',
  ping: {
    clientId: '6b910d38-1eeb-46da-b615-da34bd2def60',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  sentry: {
    ...base.sentry,
    enableSentry: true,
    dsn: 'https://9ce3f21b42fc3b79e387673d4f3fd299@o4506547460440064.ingest.sentry.io/4506559214780416',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
};
